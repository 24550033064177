import { isPlatformBrowser } from '@angular/common';
import { Injectable, PLATFORM_ID, inject } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class TenantService {
  private readonly platformId = inject(PLATFORM_ID);
  private readonly validTenants = ['demo', 'qatest'];

  isValidTenant(): boolean {
    if (!isPlatformBrowser(this.platformId)) {
      return true;
    }

    const hostname = window.location.hostname;
    const subdomain = hostname.split('.')[0];
    return this.validTenants.includes(subdomain) || hostname === 'localhost';
  }

  redirectToMainSite(): void {
    if (isPlatformBrowser(this.platformId)) {
      window.location.href = 'https://demo.rydel.dev/';
    }
  }
}
