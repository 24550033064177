import { isPlatformBrowser } from '@angular/common';
import { ChangeDetectionStrategy, Component, PLATFORM_ID, inject } from '@angular/core';
import { AuthModule } from '@angular/fire/auth';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { RouterOutlet } from '@angular/router';
import { TenantService } from '@services/tenant.service';

import { registerIcons } from '@services/icon.service';
import { registerUpdateLogingStatus } from '@services/register-update-loging-status';
import { registerUpdateTitle } from '@services/register-update-title';
import { setMetaSEO } from '@services/setMetaSEO';

@Component({
  selector: 'sup-root',
  template: `<router-outlet />`,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [RouterOutlet, AuthModule, MatSnackBarModule],
})
export class AppComponent {
  private readonly platformId = inject(PLATFORM_ID);

  constructor(private tenantService: TenantService) {
    if (isPlatformBrowser(this.platformId)) {
      this.initializeApp();
    }
  }

  private initializeApp(): void {
    if (!this.tenantService.isValidTenant()) {
      this.tenantService.redirectToMainSite();
      return;
    }

    registerUpdateTitle();
    setMetaSEO();
    registerIcons();
    registerUpdateLogingStatus();
  }
}
