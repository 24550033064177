import { ApplicationConfig, provideZoneChangeDetection } from '@angular/core';
import { provideRouter } from '@angular/router';

import { provideClientHydration } from '@angular/platform-browser';
import { provideMarkdown } from 'ngx-markdown';

import { APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { NoPreloading, Router, withInMemoryScrolling, withPreloading, withRouterConfig } from '@angular/router';
import { translateProvider } from '@app/translate-root.module';
import { GTAG_CONFIG } from '@app/utils/gtag/gtag-config';
import { environment } from '@env/environment';
import * as Sentry from '@sentry/angular';
import { CookieService } from 'ngx-cookie-service';

import { provideHttpClient, withFetch, withInterceptors } from '@angular/common/http';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { routes } from './app.routes';
import { firebaseProviders } from './firebase.providers';
import { authInterceptor } from './services/auth.interceptor';
import { tenantInterceptor } from './services/tenant.interceptor';

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true, ignoreChangesOutsideZone: true }),
    provideRouter(
      routes,
      // [],
      // withDebugTracing(),
      withPreloading(NoPreloading),
      withInMemoryScrolling({
        anchorScrolling: 'enabled',
      }),
      withRouterConfig({
        paramsInheritanceStrategy: 'always',
      }),
    ),
    provideClientHydration(),
    provideHttpClient(withFetch(), withInterceptors([authInterceptor, tenantInterceptor])),
    provideMarkdown(),
    provideAnimationsAsync(),

    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: GTAG_CONFIG,
      useValue: {
        trackingId: 'G-ELPT9CXL0P',
        trackPageviews: true,
        debug: !environment.production,
      },
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    CookieService,
    firebaseProviders,
    translateProvider(),
  ],
};
